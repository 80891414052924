.postsBlock {
  display: flex;
  flex-direction: column;
  background: $white;
  padding: 0;
  .post {
    display: flex;
    background: $white;
    justify-content: center;
    align-items: center;
    @include responsive(medium) {
      flex-wrap: wrap;
    }

    .text-and-ctas-container {
      width: 65%;
      padding: 0 2rem 0 0;
      @include responsive(medium) {
        width: 100%;
        padding: 0 !important;
        margin: 0 0 1rem 0;
      }
      .text-and-ctas {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        @include responsive(medium) {
          padding: 0;
        }
        .title {
          font-size: 32px;
          font-weight: normal;
          line-height: 1;
          margin: 0;
        }
        .description {
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          margin: 0;
        }
        .dmgButton {
          margin-top: 1.5rem;
          width: auto;
          min-width: 20rem;
          @include responsive(medium) {
            margin: 1rem 0.75rem;
            width: calc(100% - 1.5rem);
            font-size: 1rem;
            align-self: center;
          }
        }
      }
      &.noImage {
        width: 100%;
        margin-bottom: 0;
        .dmgButton {
          align-self: left;
          @include responsive(medium) {
            margin-bottom: 0;
          }
        }
      }
    }
    .image {
      width: 35%;
      position: relative;
      overflow: hidden;
      border-radius: 2rem 0 2rem 0;
      picture {
        display: flex;
        width: 100%;
        img {
          width: 100%;
        }
      }
      @include responsive(medium) {
        width: 100%;
        height: unset;
      }
    }
    &.left {
      flex-direction: row-reverse;
      .text-and-ctas-container {
        padding: 0 0 0 2rem;
        &.noImage {
          padding: 0;
        }
      }
    }
  }
}
