// Vars from Webpack config
$DEBUG: false;
$ENV: prod;

@import "~normalize.css/normalize.css";
@import "~pikaday/scss/pikaday.scss";
@import "~nouislider/nouislider.css";

@import "variables";
@import "mixins";

@import "components/fonts";
@import "components/typography";
@import "components/forms";
@import "components/pagination";
@import "components/immediate-care-locations";
@import "components/specialty-picker";
@import "components/filters";
@import "components/search-and-filters";
@import "components/button";
@import "components/pattern";
@import "components/location-card";
@import "components/provider-card";
@import "components/scheduling-provider-card";
@import "components/modal";
@import "components/pollen-sense";
@import "components/table";

@import "layout/header";
@import "layout/main";
@import "layout/footer";
@import "layout/error";
@import "layout/offline";
@import "layout/alert";
@import "layout/static";

@import "base/_landing-pages/main";
@import "base/single-procedure";
