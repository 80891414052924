@font-face {
	//bold
	font-family: "nexa";
	src: url("../../fonts/Nexa-ExtraBold.woff2") format("woff2"),
		url("../../fonts/Nexa-ExtraBold.woff") format("woff");
	font-weight: 700;
	font-display: block;
}

@font-face {
	//extra bold
	font-family: "nexa";
	src: url("../../fonts/Nexa-Heavy.woff2") format("woff2"),
		url("../../fonts/Nexa-Heavy.woff") format("woff");
	font-weight: 800;
	font-display: block;
}

@font-face {
	//semi-bold
	font-family: "nexa";
	src: url("../../fonts/Nexa-Bold.woff2") format("woff2"),
		url("../../fonts/Nexa-Bold.woff") format("woff");
	font-weight: 600;
	font-display: block;
}

@font-face {
	//regular
	font-family: "nexa";
	src: url("../../fonts/Nexa-Regular.woff2") format("woff2"),
		url("../../fonts/Nexa-Regular.woff") format("woff");
	font-style: normal;
	font-weight: 400;
	font-display: block;
}

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

$material-design-icons-font-directory-path: "~material-design-icons-iconfont/fonts/";
@import "~material-design-icons-iconfont/src/material-design-icons.scss";

// disables text selection
.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
