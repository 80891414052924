.overview {
	position: relative;
	background-color: $footer-gray;
	z-index: 0;
	.innerContent {
		display: flex;
		align-items: center;
		padding: 2rem;
		@include responsive(medium) {
			flex-wrap: wrap;
			padding: 2rem 1rem;
		}
	}
	.text {
		display: flex;
		flex-direction: column;
		padding: 2rem 2rem 0 0;
		width: 65%;
		@include responsive(medium) {
			padding: 0;
			width: 100%;
		}
		.title {
			font-size: 2rem;
			font-weight: 800;
			line-height: 1.25;
			margin: 0;
			color: $oxford;
			@include responsive(medium) {
				font-size: 1.5rem;
			}
		}
		.description {
			font-size: 18px;
			font-weight: normal;
			line-height: 1.5;
		}
	}
	.quick-links {
		width: 35%;
		overflow: hidden;
		
		@include responsive(medium) {
			width: 100%;
			margin: 1rem 0 0 0;
		}
		.title {
			box-sizing: border-box;
			padding: 1rem;
			background-color: $violet;
			font-weight: 800;
			color: $white;
			font-size: 18px;
			border-left: 5px solid $magenta;
			border-radius: 4px 0px;
		}
		.links {
			display: flex;
			flex-direction: column;
			.link {
				color: $violet;
				font-size: 16px;
				padding: 1rem;
				text-decoration: none;
				display: flex;
				align-items: center;
				border-bottom: 1px solid $magenta;
				.label {
					flex: 1 1 auto;
					color: $violet;
					font-weight: 600;
				}
				.material-icons {
					color: $violet;
				}
				&:hover {
					background-color: rgba(71, 9, 104, 0.15);
					.label {
						font-weight: 800;
					}
				}
			}
		}
	}
}
