.filters {
	max-width: 1250px;
	width: 95%;
	margin: 5px auto 0px auto;
	display: flex;

	@include responsive(x-large) {
		max-width: 95%;
	}

	@include responsive(bs-large) {
		display: none;
		max-width: 100%;
		margin: 15px auto;
	}

	.keyboard_arrow_down,
	hr {
		display: none;
	}

	.filter-entry {
		border: 1px solid $oxford;
		position: relative;
		border-radius: 4px;
		margin-left: 16px;

		&:first-child {
			margin-left: 0px;
		}

		&:hover {
			background: $off-blue;
			border-color: $sky;

			.filter-label p {
				color: $sky !important;
			}
		}

		@include responsive(large) {
			margin-left: 10px;
		}

		@include responsive(bs-large) {
			margin-left: 0px;
			position: inherit;
			border-bottom: 1px solid #e9e9e9;
		}

		.dropdown {
			display: none;
			min-width: 200px;
			border-radius: 4px;
			border: 1px solid $oxford;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.24),
				0px 4px 8px 0px rgba(0, 0, 0, 0.12);
			position: absolute;
			margin-top: 10px;
			background: $white;
			z-index: 100;
			padding: 10px 10px 10px 0px;
			max-height: 400px;
			overflow: auto;

			@include responsive(bs-large) {
				position: inherit;
				border: none;
				box-shadow: none;
				padding: 0px 10px;
				margin: 0px 0px 10px 0px;
				max-height: inherit;
				overflow: inherit;
			}

			.mdc-checkbox {
				margin: 0;
			}
		}

		.filter-label {
			border-radius: 4px;
			cursor: pointer;
			display: flex;
			align-items: center;

			@include responsive(bs-large) {
				border: none;
			}

			p,
			span {
				font-size: 14px;
				margin: 0px;
				font-weight: 700;
				color: $oxford;

				@include responsive(bs-large) {
					width: 100%;
				}
			}

			i {
				color: $white;
				padding-right: 5px;
				font-size: 20px;
			}

			p {
				padding: 4px 12px;
			}
		}

		&.active {
			background: $oxford;

			.filter-label {
				p,
				span,
				i {
					color: $white !important;
				}
			}

			&:hover {
				color: $white !important;
			}
		}

		&.selected {
			.dropdown {
				display: block;
			}
		}

		.small {
			min-width: 145px;
		}

		.large {
			min-width: 270px;
		}

		.date-picker-container {
			padding: 10px 0px 0px 10px;
		}

		.date-picker {
			margin: 0px;

			.mdc-text-field {
				height: 45px !important;
				width: 100%;
			}
		}

		.date-end {
			margin-top: 10px;
		}
	}
}

.mdc-form-field.disabled {
	.mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
		border-color: $gray !important;
	}

	label {
		color: $gray;
	}
}

.subservice {
	padding-left: 20px;
}

.filters {
	max-width: 1250px;
	width: 95%;
	margin: auto;
	display: flex;
	padding: 0 15px;

	@include responsive(bs-large) {
		max-width: 95%;
	}

	@include responsive(bs-large) {
		display: none;
	}

	.visit-type-container {
		.material-icons.close {
			display: none;
		}

		.header {
			font-size: 16px;
			font-weight: 600;
			color: #757575;
			padding: 5px 10px;
		}
	}

	.sort-container {
		.material-icons.sort {
			font-size: 25px;
			margin-left: -10px;
			padding-right: 15px;
		}
	}
}

.rating-container {
	@include responsive(bs-large) {
		display: flex;
		flex-direction: column;
		align-items: baseline;

		.filter-label {
			width: 100%;
		}
	}

	.dropdown {
		padding: 10px 0px;
	}

	.label-text {
		display: flex;
		align-items: center;

		.count {
			display: none;
			margin-left: 10px !important;
		}

		.star {
			display: none;
			font-size: 15px !important;
			padding: 0px !important;
			margin-bottom: 2px !important;
		}

		.plus {
			display: none;
			font-size: 13px !important;
		}
	}

	&.show-stars {
		.label-text {
			.count,
			.star,
			.plus {
				display: inline-block;
			}
		}
	}

	.star-container {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: center;

		.star {
			display: inline-block;
			color: $light-gold;
			font-size: 22px;
			padding: 5px;
		}

		.star-rate {
			border: 1px solid #055e55;
			cursor: pointer;

			&:first-child {
				border-right: 2px solid #055e55;
			}

			&:last-child {
				border-left: 2px solid #055e55;
			}

			&.selected-star,
			&.fill {
				background: $oxford;

				.star {
					color: $marigold !important;
				}
			}

			&:hover,
			&:hover ~ .star-rate {
				background: $oxford;

				.star {
					color: $marigold !important;
				}
			}
		}
	}
}

.event-filter-modal {
	left: 0px;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 16px;
	}

	.star-container {
		margin-left: 10px;
		justify-content: inherit !important;

		.star {
			font-size: 30px !important;
			padding: 10px !important;
		}
	}
}

.visit-type-overlay .filters {
	display: block;

	.dropdown {
		border: 1px solid $oxford;
		margin-top: 10px;
	}
}

.event-filter-modal .filter-container .filter-entry {
	cursor: pointer;
	padding: 16px;
}

.filter-entry {
	flex: 0 0 auto;
	// border: 1px solid $sky;

	&.selected {
		background: $off-blue;
		border: 1px solid $sky;

		@include responsive(bs-large) {
			background: inherit;
		}

		.label-text {
			color: $sky !important;

			@include responsive(bs-large) {
				color: $oxford !important;
			}
		}
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

.search-content {
	.search-history,
	.auto-complete {
		position: absolute;
		width: 100%;
		box-sizing: border-box;
		top: 105%;
		z-index: 100;
		background: $white;
		border-radius: 4px;
		border: 1.5px solid $light-blue;
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
		padding: 10px;
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
		pointer-events: none;

		@include responsive(bs-large) {
			position: relative;
			display: none;
			margin-top: 10px;
		}
	}

	.search-history {
		.item {
			font-size: 16px;
			font-weight: normal;
			padding: 10px 10px;
			cursor: pointer;
			color: $oxford;

			&:hover,
			&.selected {
				background: $hover-option-blue;
				color: $oxford;
			}
		}

		&.show-recent-history {
			opacity: 1;
			pointer-events: all;

			@include responsive(bs-large) {
				position: relative;
				display: block;
			}
		}
	}

	.auto-complete {
		.item {
			font-size: 16px;
			font-weight: normal;
			padding: 10px 10px;
			cursor: pointer;
			color: $oxford;

			&:hover,
			&.selected {
				background: $sky;
				color: $oxford;
			}
		}

		&.show-auto-complete {
			opacity: 1;
			pointer-events: all;

			@include responsive(bs-large) {
				position: relative;
				display: block;
			}
		}
	}

	.clear {
		font-size: 16px;
		font-weight: 600;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}

	.header {
		margin: 0;
		padding: 0;
		font-size: 16px;
		font-weight: 600;
	}
}

.ages-container {
	.dropdown {
		width: 420px;

		@include responsive(bs-large) {
			width: 100%;
		}

		hr {
			margin-left: 10px;
			display: block !important;
			border: 0.5px solid $gray;
		}

		.indicator {
			font-size: 14px !important;
			font-weight: 500 !important;
			margin: 0px !important;
			padding-left: 38px;

			@include responsive(bs-large) {
				padding-left: 45px;
			}

			span {
				font-size: 14px;
				font-weight: 700;
			}
		}

		#slider,
		#slider-mobile {
			margin: 30px 10px;
			width: calc(100% - 20px);
			height: 3px;
			background: $hover-blue;
			border: none;
			box-shadow: none;

			.noUi-tooltip {
				display: none;
				padding: 0px;
				border: none;
				font-size: 12px;
				font-family: nexa, sans-serif;
			}

			.noUi-active .noUi-tooltip {
				display: block;
			}

			.noUi-connect {
				background: $oxford !important;
			}

			.noUi-origin {
				.noUi-handle {
					background: $oxford !important;
					box-shadow: none;
					top: -10px;
					height: 20px;
					width: 20px;
					border-radius: 50%;

					&:before,
					&:after {
						display: none;
					}
				}
			}
		}
	}
}
