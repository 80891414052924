.alertModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	box-sizing: border-box;
	padding: 1rem;
	background-color: rgba(96, 96, 96, 0.6);
	z-index: 9999999;
	* {
		box-sizing: border-box;
	}
	&.highPriority {
		.modalContent {
			.title {
				background-color: $marigold;
			}
		}
	}
	&.mostUrgent {
		.modalContent {
			.title {
				background-color: $alert;
				color: $white;
			}
			.material-icons {
				color: $white;
			}
		}
	}
	&.dismissible {
		.modalContent {
			.title {
				padding: 1rem 2.5rem;
			}
		}
	}
	.vertical-alignment-helper {
		display: table;
		height: 100%;
		width: 100%;
		pointer-events: none;
		.vertical-align-center {
			display: table-cell;
			vertical-align: middle;
			pointer-events: none;
		}
	}
	.modalContent {
		display: block;
		background-color: #ffffff;
		box-sizing: border-box;
		position: relative;
		border-radius: 0.25rem;
		width: 743px;
		height: auto;
		margin: 0 auto;
		pointer-events: all;
		border-radius: 0.5rem;
		overflow: hidden;
		a.dmgButton {
			text-decoration: none;
		}
		.closeModal {
			line-height: 1.5rem;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0.25rem;
			cursor: pointer;
			color: $oxford;
		}
		.title {
			padding: 1rem;
			margin: 0;
			font-size: 20px;
			font-weight: 900;
			color: $oxford;
			h5 {
				text-align: center;
				line-height: 1.5rem;
				margin: 0;
				font-size: inherit;
				color: inherit;
				font-weight: inherit;
				display: flex;
				align-items: center;
				justify-content: center;
				.material-icons {
					margin: 0 0.5rem 0 0;
				}
			}
		}
		.content {
			padding: 1.5rem 2.5rem 1.5rem 2.5rem;
			margin: 0;
			width: 100%;
			p {
				margin: 0 0 1rem 0;
				text-align: center;
				line-height: 1.375rem;
				overflow: hidden;
				&:last-of-type {
					margin: 0;
				}
			}
		}

		.singleCta {
			padding: 0rem 2.5rem 1.5rem 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			
			.ctaExitLinkContainer {
				button {
					font-size: 1.375rem;
					font-weight: 800;
					line-height: 2.063rem;
					margin-right: 1.938rem;
					width: 316px;
					min-height: 56px;
					a {
						padding: 14px 15.37px 9px 14.63px;
					}
				}
			}

			.ctaContinueContainer {
				button {
					font-size: 1.375rem;
					font-weight: 800;
					line-height: 2.063rem;
					padding: 8px 0px 8px 0px;
					margin-right: 1.938rem;
					width: 316px;
					min-height: 56px;
					a {
						padding: 0px;
					}
					.cta {
						padding: 0px;
					}
				}
			}
		}

		.ctas {
			padding: 0rem 2.5rem 1.5rem 2.5rem;
			display: flex;
			flex-direction: row;

			.ctaExitLinkContainer {
				button {
					font-size: 1.375rem;
					font-weight: 800;
					line-height: 2.063rem;
					margin-right: 1.938rem;
					width: 316px;
					min-height: 100%;
					a {
						padding: 14px 15.37px 9px 14.63px;
					}
				}
			}

			.ctaContinueContainer {
				button {
					font-size: 1.375rem;
					font-weight: 800;
					line-height: 2.063rem;
					padding: 8px 0px 8px 0px;
					margin-right: 1.938rem;
					width: 316px;
					min-height: 100%;
					a {
						padding: 0px;
					}
					.cta {
						padding: 0px;
					}
				}
			}
		}
	}

	@include responsive(small) {

		&.dismissible {
			.modalContent {
				.title {
					padding: 28px 16px;
				}
			}
		}

		.modalContent{
			width: 335px;

			.title {
				padding: 28px 16px;
				h5 {
					line-height: 1.375rem;

					.material-icons {
						display: none;
					}
				}
			}

			.content {
				padding: 24px 16px 24px 16px;
				margin: 0;
				width: 100%;
				p {
					font-size: 16px;
					-webkit-line-clamp: 4;
				}
			}

			.singleCta {
				padding: 0px;
				margin: 0px 16px 24px 16px;

				.ctaExitLinkContainer {
					button {
						width: 303px;
						margin: 0px;
						padding: 0px;
						a {
							line-height: 24px;
							margin: 8px 14.66px 8px 14.66px;
						}
					}
				}

				.ctaContinueContainer {
					button {
						width: 303px;
						margin: 0px;
						padding: 0px;
						a {
							line-height: 24px;
						}
						.cta {
							padding: 0px;
							margin: 8px 0px 8px 0px;
						}
					}
				}
			}

			.ctas {
				padding: 0px 16px 24px 16px;
				flex-direction: column;

				.ctaExitLinkContainer {
					button {
						width: 303px;
						margin-top: 16px;
						min-height: auto;
						a {
							line-height: 24px;
						}
					}
				}

				.ctaContinueContainer {
					button {
						width: 303px;
						margin-top: 16px;
						min-height: auto;
						a {
							line-height: 24px;
						}
					}
				}
			}
		}
	}
}
