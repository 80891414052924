.locationsBlock {

  button.dmgButton {
    min-width: 20rem;
  }
  
  .locationsList {  
    display: flex;
    max-width: 804px;
    flex-wrap: wrap;
    margin: 0 auto;
  
    &.center {
      justify-content: center;
    }
    &.left {
      justify-content: left;
    }
  
    &>* {
      width: 33.333%;
    }

    .locationCard {
      border-radius: 32px 0px;
    }
    
    @include responsive(medium) {
      max-width: 100%;
      margin-bottom: 1.5rem;
      &>* {
        width: 50%
      }
    }
    @include responsive(small) {
      &>* {
        width: 100%;
        padding-inline: 2.125rem;
      }
    }
  
  }
}