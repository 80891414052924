.locationCardContainer {
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	padding: 0.5rem;
	* {
		box-sizing: border-box;
	}
	.locationCard {
		padding: 1rem;
		width: 100%;
		max-width: 360px;
		display: flex;
		&:hover {
			background-color: $white;
			box-shadow: 0px 2px 8px 0px #00000033;
			.phone-number,
			.toggle,
			.getDirections a {
				color: $magenta !important;
			}
		}
		.locationIcon {
			width: 1.25rem;
			img {
				width: 100%;
			}
		}
		p {
			font-size: 1rem;
			margin: 1rem 0;
		}
		span {
			font-size: inherit;
			font-weight: inherit;
			color: inherit;
		}
		.locationDetail {
			width: calc(100% - 1.25rem);
			padding: 0 0 0 1rem;
			& > a {
				text-decoration: none;
			}
			.city {
				color: $oxford;
				font-size: 18px;
				font-weight: 800;
				span {
					color: inherit;
					font-size: inherit;
					font-weight: inherit;
					&.badge {
						display: block;
						width: 100%;
						font-size: 12px;
					}
				}
			}
			.city,
			.address1,
			.suite,
			.address2 {
				margin: 0;
			}
			.temporarilyClosed {
				color: $alert;
				font-weight: 800;
			}
			.phoneNumber {
				margin: 1rem 0 0 0;
				font-weight: 800;
				a {
					font-weight: inherit;
				}
			}
			.toggle {
				display: flex;
				align-items: center;
				color: $violet;
				font-weight: 800;
				cursor: pointer;
				&:hover {
					span {
						text-decoration: underline;
					}
					.material-icons {
						text-decoration: none;
					}
				}
				span {
					color: inherit;
					font-weight: inherit;
				}
				.material-icons {
					font-size: 1.5rem;
				}
			}
			.viewAllHours {
				margin: 1rem 0 0 0;
				&.active {
					.toggle {
						.material-icons {
							transform: scaleY(-1);
						} 
					}
					.officeHours {
						display: block;
					}
				}
				.officeHours {
					display: none;
					margin: 0.5rem 0 0 0;
					.row {
						display: flex;
						.col {
							color: $dark-2;
							&:nth-of-type(1) {
								width: 40%;
								padding: 0 0.25rem 0 0;
								text-align: left;
							}
							&:nth-of-type(2) {
								width: 60%;
								padding: 0 0 0 0.25rem;
								text-align: right;
							}
						}
					}
				}
			}
			.viewAllHoursLab {
				margin: 1rem 0 0 0;
				&.active {
					.toggle {
						.material-icons {
							transform: scaleY(-1);
						} 
					}
					.officeHours {
						display: block;
					}
				}
				.officeHours {
					display: none;
					margin: 0.5rem 0 0 0;
					.row {
						display: flex;
						.col {
							color: $dark-2;
							&:nth-of-type(1) {
								width: 40%;
								padding: 0 0.25rem 0 0;
								text-align: left;
							}
							&:nth-of-type(2) {
								width: 60%;
								padding: 0 0 0 0.25rem;
								text-align: right;
							}
						}
					}
				}
			}
			.getDirections {
				margin: 1rem 0 0 0;
				a {
					font-weight: bold;
					font-size: 1rem;
					font-weight: 800;
				}
			}
		}
		.nearestLocations {
			.nearestLocationsContainer {
				max-height: 0px;
				overflow: hidden;
				.nearestLocation {
					margin: 0.5rem 0 0 0;
					.title {
						display: flex;
						align-items: flex-start;
						a {
							font-weight: 800;
							text-decoration: none;
							color: $oxford !important;
							&:hover {
								color: $magenta;
							}
							.distanceIndicator {
								text-decoration: none;
								padding: 0 0 0 0.25rem;
								color: $oxford;
								font-size: 14px;
								font-weight: 600;
							}
						}
					}
					.location-icon {
						width: 1.25rem;
						padding: 0.25rem;
					}
					p {
						margin: 0;
					}
				}
			}
			&.active {
				.nearestLocationsContainer {
					max-height: unset;
				}
				.toggle {
					.material-icons {
						transform: scaleY(-1);
					}
				}
			}
		}
	}
}
