.providerCardContainer {
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	padding: 0.5rem;
	* {
		box-sizing: border-box;
	}
	.physician-short {
		padding: 1rem;
		min-height: 308px;
		width: 100%;
		max-width: 200px;
		&:hover {
			background-color: white;
			box-shadow: 0px 2px 8px 0px #00000033;
			h5 {
				color: $magenta;
			}
		}
		> a {
			text-decoration: none;
		}
		span {
			font-size: inherit;
		}
		.image {
			width: 120px;
			height: 120px;
			margin: 0 auto;
			border-radius: 2px;
			overflow: hidden;
			picture {
				display: block;
				width: 100%;
				height: 100%;
				img {
					height: 100%;
					object-fit: cover;
				}
			}
		}
		h5 {
			font-size: 16px;
			font-weight: 900;
			line-height: 20px;
			letter-spacing: 0px;
			text-align: center;
			margin: 1rem 0;
			color: $violet;
			@include truncate(2);
		}
		.stars {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin: 1rem 0;
			.rating-number {
				color: $dark-2;
				font-size: 18px;
				font-weight: 900;
				height: unset;
				padding: 0 0.25rem;
			}
			.star-rating {
				display: flex;
				align-items: center;
				i,
				.star,
				.star_half {
					color: $marigold;
					font-size: 1rem;
				}
			}
		}
		.specialties {
			text-align: center;
			font-weight: 800;
			color: $oxford;
			font-size: 14px;
			margin: 0.5rem 0;
			@include truncate(2);
		}
		.locations {
			text-align: center;
			color: $oxford;
			font-weight: 400;
			font-size: 14px;
			margin: 0.5rem 0;
			@include truncate(1);
		}
	}
}
