.show-locations {
    form {
      button {
        color: $white;
        font-size: 14px;
        font-weight: bold;
        padding: 16px 24px;
        border: none;
        border-radius: 4px;
      }
    }

    #closest-express-care,
    #closest-immediate-care-center {
      .title {
        color: $oxford;
        font-size: 20px;
        font-weight: 900;
      }

      .details-content {
        margin: 20px 0px;
        display: flex;
        flex-direction: row;

        @include responsive(medium) {
          flex-direction: column;
          margin: 10px 0px;
        }

        .content-mobile {
          display: none;

          @include responsive(medium) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0px;
            padding-bottom: 10px;
            border-bottom: 1px solid $sky;

            .green-text {
              width: inherit;
            }
          }

          .phone-number {
            font-size: 16px;
            font-weight: 800;
          }

          .directions {
            font-size: 16px;
            font-weight: 800;
          }

          .hours {
            .open, .closed {
              font-size: 16px;
            }
          }
        }

        .address {
          border-right: 1px solid $sky;
          text-align: center;
          width: 50%;

          &.no-hours {
            width: 100%;
            border-right: none;
          }

          .miles-container {
            display: none;
          }

          @include responsive(medium) {
            border-right: none;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            text-align: left;

            .content-desktop {

              .directions,
              .phone-number {
                display: none;
              }
            }

            .miles-container {
              display: flex;
              align-items: flex-end;

              .miles {
                display: block !important;
                font-size: 12px;

                .bolded {
                  font-size: 18px;
                }
              }
            }
          }

          &.no-border {
            border-right: none;
            width: 100%;
          }

          .phone-number {
            display: block;
            margin: 5px 0px;
            font-size: 16px;
            font-weight: 800;
            text-decoration: none !important;

            &:hover {
              text-decoration: underline !important;
            }
          }

          .directions {
            font-size: 16px;
            font-weight: 800;
            text-decoration: none !important;

            &:hover {
              text-decoration: underline !important;
            }
          }

          p {
            margin: 0px;
            font-size: 16px;
          }
        }

        .hours {
          text-align: center;
          width: 50%;

          @include responsive(medium) {
            width: 100%;

            .day {
              display: none;
            }
          }

          &.temp-closed {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .open,
          .closed {
            color: $violet;
            font-weight: 800;
            margin: 0px 0px 5px 0px;

            span {
              color: $violet;
              font-weight: 800;
            }
          }

          .closed {
            color: $violet;
          }

          .disclaimer {
            margin: 5px 0px;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
          }

          .day {
            font-size: 14px;
            margin: 0px;

            span {
              font-size: 14px;
            }
          }
        }
      }

      .miles {
        color: $oxford;
        font-size: 16px;
        font-weight: bold;

        .bolded {
          color: $oxford;
          font-size: 28px;
          font-weight: bold;
        }

        @include responsive(medium) {
          display: none;
        }
      }

      .green-text {
        display: block;
        width: 100%;
        font-weight: 600;
        color: $light-green;
        font-size: 14px;
        margin: 10px 0px;
        text-decoration: none;
      }
    }
  }