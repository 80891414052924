.cards {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  justify-content: center;
  flex-wrap: nowrap;
  
  hr {
    margin: 1.25rem 0;
  }
  @include responsive(large) {
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  .card {
    flex: 0 1 33.333%;
    border: 2px solid $oxford;
    border-radius: 20px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    @include responsive(large) {
      flex: 0 1 100%;
    }

    .top {
      .title {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        padding: 2rem 1.125rem 0 1.125rem;
        h3 {
          font-size: 1.25rem;
          color: $oxford;
          font-weight: 900;
          margin: 0;
          @include responsive(medium) {
            font-size: 1.125rem;
          }
        }
        @include responsive(medium) {
          padding: 2rem 1rem 0 1rem;
        }
        .icon {
          display: flex;
          flex-shrink: 0;
          width: 2.5rem;
          height: 2.5rem;
          picture {
            width: 100%;
            display: flex;
            height: fit-content;
            align-self: center;
            img {
              width: 100%;
            }
          }
          @include responsive(medium) {
            width: 2rem;
            height: 2rem;
          }
        }
      }
      .description {
        padding: 0 1.125rem;
        margin-bottom: 5rem;
        @include responsive(medium) {
          margin-bottom: 2.5rem;
        }
      }
    }

    .button {
      padding: 0 1.125rem 2rem 1.125rem;
      @include responsive(medium) {
        padding: 0 1rem 2rem 1rem;
      }
      .dmgButton {
        width: 100%;
        a {
          font-size: 1.375rem;
          @include responsive(medium) {
            font-size: 1.375rem;
          }
        }
      }
    }
  }
}