.featured-post {
	width: 100%;
	position: relative;
	#bgHolder {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		z-index: -1;
		> div {
			height: 100%;
			&:nth-child(1) {
				width: 50%;
				background-color: $oxford;
				border-radius: 0 2rem 0 0;
			}
			&:nth-child(2) {
				width: 50%;
				background-color: $sky;
				border-radius: 2rem 0 0 0;
			}
		}
		@include responsive(medium) {
			display: none;
		}
	}
	.text-and-ctas-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 50%;
		.text-and-ctas {
			padding: 0 60px 0 80px;
			display: flex;
			flex-direction: column;
			align-items: baseline;
			flex: 1;
			.title {
				font-size: 32px;
				font-weight: normal;
				line-height: 1;
				margin: 0;
				color: $white;
			}
			.description {
				font-size: 18px;
				font-weight: normal;
				line-height: 1.5;
				color: $white;
				margin: 15px 0 25px;
				* {
					color: $white;
				}
			}
			.cta {
				display: block;
				padding: 16px;
				text-decoration: none;
				text-align: center;
				font-weight: bold;
				margin-right: 15px;
				background: $white;
				border-radius: 4px;
				color: #0e333a;
				font-size: 16px;
			}
		}
	}
	.innerContent {
		display: flex;
		color: $white;
		.title {
			color: $white;
			margin: 0;
			line-height: 1.25;
			font-size: 2rem;
			@include responsive(medium) {
				font-size: 1.5rem;
			}
		}
		.description {
			margin: 1rem 0;
			a {
				color: $white !important;
				font-weight: bold !important;

				&:hover {
					text-decoration: underline !important;
				}
			}
			* {
				color: $white;
			}
		}
		.image {
			width: 50%;
			@include responsive(medium) {
				width: 100%;
			}
			picture {
				display: flex;
				width: 100%;
				height: 100%;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 2rem 0 2rem 0;
				}
			}
		}
		> div {
			width: 50%;
			&:nth-child(1) {
				padding: 2rem 3rem 2rem 2rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		@include responsive(medium) {
			flex-wrap: wrap;
			> div {
				width: 100% !important;
				&:nth-child(1) {
					border-radius: 2rem 0 0 0;
					background-color: $oxford;
					padding: 2rem;
				}
				&:nth-child(2) {
					padding: 2rem;
					background-color: $sky;
					border-radius: 0 0 2rem 0;
				}
			}
		}
		button {
			margin: 0 0 1rem 0;
			@include responsive(medium) {
				margin: 0;
			}
		}
	}
}
