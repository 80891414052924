.rich-text-content .table-container {
	width: 100%;
	overflow-x: auto;
	table {
		table-layout: fixed;
		min-width: 100%;
		border-collapse: collapse;
		white-space: nowrap;
		th,
		td {
			padding: 1.5rem 1rem;
			text-align: left;
			background-color: inherit;
			p {
				color: inherit;
				margin: inherit;
				font-size: inherit;
				font-weight: inherit;
			}
		}
		th {
			font-weight: 900;
			color: $oxford;
		}
		tr:nth-child(odd) {
			background-color: $off-blue;
		}
		tr:nth-child(even) {
			background-color: $white;
		}
		thead {
			tr,
			tr:nth-child(odd) {
				background-color: $white;
				border-bottom: 2px solid $sky;
			}
		}
		th:first-child,
		td:first-child {
			position: sticky;
			left: 0;
			z-index: 1;
		}
	}
	+ .scroll-view-more {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem 0;
		&::before {
			content: "";
			background-image: url(../../img/icons/scroll.svg);
			display: block;
			width: 3rem;
			height: 1rem;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin: 0 0.5rem 0 0;
		}
	}
}
