.video {
  span p {
    margin-bottom: 2.5rem;
    @include responsive(medium) {
      margin-bottom: 1.5rem;
    }
  }

  .video-wrapper {
    width: min(757px, 100%);
    aspect-ratio: 2.4;
    margin: 0 auto;
  }
}