.pollenSense {
    width: 100%;
	box-sizing: border-box;
	padding: 50px;
	* {
		box-sizing: border-box;
	}
	.pollenSenseContainer {
		border: 2px solid $sky;
		border-radius: 2rem 0;
		padding: 2rem;
		width: 100%;
		margin: 0 auto;
		h4 {
			text-align: center;
			font-size: 1.75rem;
			font-weight: 900;
			color: $oxford;
			margin: 0 0 2rem 0;
		}
	}
	.categories {
		display: flex;
		.category {
			border-right: 1px solid $sky;
			padding: 1rem;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			width: 20%;
			&:last-of-type {
				border-right: none;
				border-bottom: 0;
			}
			> div {
				padding: 0 0.5rem;
				text-align: center;
				h5,
				p {
					color: $oxford;
					margin: 0;
				}
				h5 {
					font-size: 18px;
					font-weight: 900;
				}
			}
		}
	}
	@include responsive(bs-large) {
		padding: 1rem;
	}
	@include responsive(large) {
		.pollenSenseContainer {
			padding: 1rem;
			max-width: 400px;
			h4 {
				margin: 0 0 1rem 0;
			}
			.categories {
				display: block;
				.category {
					width: 100%;
					border-right: 0;
					padding: 0.5rem 0;
					align-items: center;
					> div {
						padding: 0;
					}
					> div:nth-child(1) {
						width: 35%;
						padding: 0.25rem;
						img {
							display: none;
						}
					}
					> div:nth-child(2) {
						width: 65%;
						border-left: 1px solid $sky;
						display: flex;
						align-items: center;
						justify-content: space-evenly;
						h5 {
							width: 70px;
						}
					}
					h5 {
						text-align: left;
					}
				}
			}
		}
	}
}