.contentBlock {
  padding: 2.5rem 5rem;
  @include responsive(medium) {
    padding: 2rem 1rem;
  }

  .divider {
    width: 100%;
    border-top: 1px solid $sky;
    margin-block: 2.5rem;
  }

  h2 {
    color: $oxford;
    font-size: 2rem;
    font-weight: 900;
    margin: 0;
    @include responsive(medium) {
      font-size: 1.25rem;
    }
  }

  p {
    font-weight: 400;
  }

  .dmgButton a {
   @include responsive(medium) {
    font-size: 1rem;
   }
  }

  .cta {
    width: 100%;
    margin-top: 2.5rem;
    .dmgButton.center {
      display: block;
      margin-inline: auto;
      @include responsive(medium) {
        width: 100%;
      }
    }
  }

  @import "./cards";
  @import "./providers";
  @import "./posts";
  @import "./video";
  @import "./locations";
}
