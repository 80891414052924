.providers {
  display: flex;
  max-width: 600px;
  flex-wrap: wrap;
  margin: 0 auto;

  &.center {
    justify-content: center;
  }
  &.left {
    justify-content: left;
  }

  &>* {
    width: 33.333%
  }
  
  @include responsive(medium) {
    max-width: 100%;
    &>* {
      width: 50%
    }
  }
}