.tile-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .no-search-results {
        margin: 50px auto 25px auto;
        text-align: center;

        img {
            width: 80px;
        }

        h2 {
            margin: 5px 0px 0px 0px;
        }

        p {
            margin: 0px;
        }
    }

    div.physician-link {
        width: 50%;
        background: $white;
        text-decoration: none;
        border-radius: 0.25rem;
        cursor: pointer;
        box-sizing: border-box;
        min-height: 165px;
        padding: 0.5rem;
        @include responsive(large) {
            width: 100%;
        }
        @include responsive(bs-large) {
            width: 50%;
        }
        @include responsive(medium) {
            width: 100%;
        }
        * {
            box-sizing: border-box;
        }
        .tile {
            height: 100%;
            border-radius: 0.25rem;
            overflow: hidden;
            .single-tile {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-bottom: 1px solid $magenta;
                padding: 0.5rem;
            }
        }
        &:hover {
            .tile {
                box-shadow: 0px 2px 8px 0px #00000033;
            }
            .single-tile {
                h2 {
                    color: $magenta;
                }
            }
            .appointment-time span {
                color: $magenta !important;
                text-decoration: underline;
            }
            .scheduleOnline {
                color: $magenta;
            }
        }
        .biography-content {
            display: flex;

            .image {
                width: 120px;
                height: 120px;
                margin: 0 auto;
                border-radius: 2px;
                overflow: hidden;
                picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .specialties {
                line-height: 1;
                @include truncate(2);
            }

            .stars {
                margin: 0.5rem 0;

                .rating-number {
                    color: $dark-2;
                    font-size: 16px;
                    font-weight: 800;
                    line-height: 22px;
                    float: left;
                    margin: 0 0.25rem 0 0;
                }

                .star-rating {
                    height: 32px;
                    white-space: nowrap;

                    .material-icons {
                        color: $marigold;
                        font-size: 20px;
                    }

                    @include responsive(medium) {
                        height: 16px;

                        .material-icons {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        span.location-name {
            &:not(:last-of-type):after {
                content: ",";
                margin-left: -3px;
                color: $dark-blue-text;
            }
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 120px);
            padding: 0 0 0 0.5rem;

            a {
                display: inline-block;
                margin: 1.5rem auto 0px 0px;
                text-decoration: underline;
                text-transform: capitalize;
            }

            h2 {
                margin: 0px;
                font-size: 16px;
                font-weight: 900;
                line-height: 20px;
                color: $violet;
            }

            .speciality-name {
                font-size: 14px;
                font-weight: normal;
                line-height: 18px;
                color: $oxford;
                &:after {
                    content: ", ";
                }

                &:last-child {
                    &:after {
                        content: "";
                    }
                }
            }

            .locations {
                font-size: 14px;
                p,
                span {
                    margin: 0px;
                    font-size: inherit;
                    font-weight: normal;
                    line-height: 18px;
                    color: $dark-2;
                }
                p {
                    @include truncate(1);
                }
                .location-name {
                    color: $oxford;
                }
            }
        }

        .appointment-time {
            p {
                font-size: 14px;
                font-weight: normal;
                margin: 0px;
                padding-top: 12px;
                color: $dark-2;
                line-height: 22px;

                span {
                    font-weight: 800 !important;
                    color: $violet !important;
                    font-size: 16px;
                }
            }
        }
        .bottomBorder {
            height: 1px;
            width: 100%;
            background-color: $magenta;
        }
        .scheduleOnline {
            padding: 1rem 0.5rem 0 130px;
            font-weight: bold;
            color: $violet;
            a {
                font-weight: inherit;
                color: inherit !important;
            }
        }
    }

    &.chosen-visit-type {
        .visit-type-overlay {
            display: none;
        }

        .single-day {
            &:not(.header) {
                filter: none !important;
                pointer-events: auto !important;
            }
        }
    }
}

.careTeamMobile {
    display: none;
    @include responsive(x-large) {
        display: block;
    }
}
.careTeamDesktop {
    width: 100%;
    @include responsive(x-large) {
        display: none;
    }
}