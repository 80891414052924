.offline-container {
    max-width: 1280px;
    margin: 5% auto 0 auto;

    @include responsive(small) {
        margin: 0 auto;
    }

    div.inner-container {
        width: 900px;
        min-height: 400px;
        margin: 30px auto;
        padding: 40px 130px;
        background-color: $white;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: left;

        @include responsive(small) {
            padding: 15px;
            margin: 15px;
        }

        .separator {
            border-top: 1px solid $sky;
            width: 100%;
            margin: 15px 0 25px;
        }

        .maintenance-main-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 50px;

            @include responsive(small) {
                margin-top: 20px;
                flex-direction: column-reverse;
            }

            .maintenance-icon {
                background-image: url(../../img/ico-wrench.svg);
                width: 55px;
                height: 55px;
                background-repeat: no-repeat;
                background-size: contain;
            }

            .maintenance-title {
                color: $dark-2;
                font-family: nexa, sans-serif;
                font-weight: bold;
                width: 90%;
                margin-right: 20px;

                @include responsive(small) {
                    margin-top: 20px;
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }

        .maintenance-description {
            color: $dark-2;
            font-family: nexa, sans-serif;
            font-size: 20px;
            font-weight: 600;

            @include responsive(small) {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}
